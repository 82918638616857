import * as uuid from 'uuid';
import * as a11y from './a11y.js';
import { dispatch } from './event-dispatch.js';
import * as menu from './menu.js';
import * as shroud from './shroud.js';

/**
 * Opens the side cart. Emits a cart-drawer-opened event.
 *
 * The cart-drawer-opened event is primarily only intended for downstream analytics, not managing UI
 * state. Avoid implementing any ui management logic that uses this event.
 */
export function open() {
  // Before showing the cart drawer, reset its scroll position. This ensures error messages are
  // immediately visible if an error triggered the drawer to open.

  // TODO: this selector could be improved. we will only have a single element on the page that
  // represents the cart, so prefer to use an id. even if we stick with class, we should use the
  // least amount of qualification. so check if anything else even uses the class `Cart`. if there
  // is no confusion, remove the `shopify-section` class from this selector.

  const cartElement = document.querySelector('.shopify-section.Cart');

  // The cart element should always be found, but we have a history of issues with elements not
  // being found so we have some paranoia here. It might be excessive.

  if (cartElement) {
    cartElement.scrollTop = 0;
  } else {
    console.log('unable to find cart element to reset scroll position on open');
  }

  // The site uses a strange global data attribute on body to coordinate which modal-like is
  // currently the visible one, if any, across multiple unrelated sections of the code. The global
  // state is managed by the menu class. We only want one modal-like element visible at a time, so
  // close any other modal-like that is open.

  // TODO: is this code still correct? we are no longer calling menu.open('cart') here, so why is
  // this checking that menuOpen is not cart?

  if (document.body.dataset.menuOpen && document.body.dataset.menuOpen !== 'cart') {
    menu.close(<menu.MenuName>document.body.dataset.menuOpen);
  }

  shroud.show();

  addEventListener('shroud-overlay-clicked', onShroudClick);

  const cartDrawer = document.querySelector('cart-drawer');
  a11y.clearInert(cartDrawer);
  cartDrawer.dataset.show = 'true';

  dispatch('cart-drawer-opened', { event_id: uuid.v4() });
}

/**
 * Returns whether the cart drawer is visible.
 *
 * The return value is misleading on pages that do not have the cart drawer such as on the cart page
 * itself. On such pages the return value is false.
 */
export function isOpen() {
  const cartDrawer = document.querySelector('cart-drawer');
  return cartDrawer && cartDrawer.dataset.show === 'true';
}

/**
 * Closes the cart drawer.
 */
export function close() {
  removeEventListener('shroud-overlay-clicked', onShroudClick);

  shroud.hide();

  const cartDrawer = document.querySelector('cart-drawer');
  cartDrawer.dataset.show = 'false';
  a11y.makeInert(cartDrawer);

  dispatch('cart-drawer-closed');
}

function onShroudClick(_event: Event) {
  close();
}

/**
 * Emitted when the cart drawer is opened. Occurs frequently, so consider debouncing.
 */
type CartDrawerOpenedEvent = CustomEvent<{ event_id: string; }>;

/**
 * Emitted when the cart drawer is closed.
 */
type CartDrawerClosedEvent = CustomEvent<undefined>;

declare global {
  interface WindowEventMap {
    'cart-drawer-closed': CartDrawerClosedEvent;
    'cart-drawer-opened': CartDrawerOpenedEvent;
  }
}
